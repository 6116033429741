import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';
import { featureFlag } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown<Value = number>(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem<Value>[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem<Value>[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem() {
    const memo_types = [
      {
        label: 'External',
        value: 'external',
        context: {
          display_name: 'แบบขออนุมัติภายนอก',
          display_name_en: 'External',
        },
      },
      {
        label: 'Internal',
        value: 'internal',
        context: {
          display_name: 'แบบขออนุมัติภายใน',
          display_name_en: 'Internal',
        },
      },
      {
        label: 'Purchase Request',
        value: 'purchase_request',
        context: {
          display_name: 'แบบขออนุมัติการซื้อ',
          display_name_en: 'Purchase Request',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
    ];
    const contract_types = [
      {
        label: 'Agreement',
        value: 'contract',
        context: {
          display_name: 'สัญญา',
          display_name_en: 'Agreement',
        },
      },
    ];

    if (featureFlag.memo_enable && featureFlag.econtract_enable) {
      return memo_types.concat(contract_types);
    } else if (featureFlag.memo_enable) {
      return memo_types;
    } else if (featureFlag.econtract_enable) {
      return contract_types;
    } else {
      return [];
    }
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
